(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/services/assets/helpers/resolve-error-message.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/services/assets/helpers/resolve-error-message.js');
"use strict";


const {
  processErrorCode
} = svs.components.marketplaceShared.competitionErrorCodes;
const resolveErrorMessage = error => {
  const processedErrorCode = processErrorCode(error);
  let resolvedMessage;
  if (processedErrorCode.errorMessage) {
    resolvedMessage = processedErrorCode.errorMessage;
  } else if (processedErrorCode.errorTitle) {
    resolvedMessage = processedErrorCode.errorTitle;
  } else {
    resolvedMessage = 'Något gick fel';
  }
  return resolvedMessage;
};
setGlobal('svs.components.marketplaceCompetition.services.helpers.resolveErrorMessage', resolveErrorMessage);

 })(window);